/* eslint-disable import/no-unresolved */
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { Helmet } from 'react-helmet-async'

// Language
// eslint-disable-next-line import/extensions
import { getLanguage } from 'services/language'

// Icon
import favicon from 'img/favicon.png'

const query = graphql`
  {
    site {
      siteMetadata {
        pathPrefix
        title
        description
        url
      }
    }
  }
`

const getYoastMeta = data => {
  const response = { title: null, description: null }

  if(data) {
    const yoastTitleObj = data.find(
      ({ property }) => property === 'og:title'
    )

    if(yoastTitleObj) {
      response.title = yoastTitleObj.content
    }

    const yoastDescriptionObj = data.find(
      ({ name }) => name === 'description'
    )

    if(yoastDescriptionObj) {
      response.description = yoastDescriptionObj.content
    }
  }

  return response
}

const removeHTML = content => {
  let newContent = content

  if(typeof document !== 'undefined') {
    const titleHtml = newContent
    const div = document.createElement('div')
    div.innerHTML = titleHtml
    newContent = div.innerText
  }

  return newContent
}

function SEO({
  title,
  description,
  path,
  image,
  type,
  yoast,
}) {
  const language = getLanguage()

  return (
    <StaticQuery
      query={query}
      render={queryResponse => {
        const yoastMeta = getYoastMeta(yoast)

        const seoTitle = title || yoastMeta.title || queryResponse.site.siteMetadata.title || ''
        const seoDescription = description || yoastMeta.description || queryResponse.site.siteMetadata.description || ''
        const seoPath = `${queryResponse.site.siteMetadata.url}${path || ''}`
        const seoImage = image || {}
        const seoType = type || 'website'

        if(seoImage.url && seoImage.url.indexOf('http') === -1) {
          seoImage.url = `${queryResponse.site.siteMetadata.url.replace(`/${queryResponse.site.siteMetadata.pathPrefix}`, '')}${seoImage.url}`
        }

        return (
          <>
            <GatsbySeo 
              title={removeHTML(seoTitle)}
              description={removeHTML(seoDescription)}
              canonical={seoPath}
              locale={language}
              openGraph={{
                type: seoType,
                url: seoPath,
                title: seoTitle,
                description: seoDescription,
                images: [
                  seoImage
                ]
              }}
            />
            <Helmet>
              <html lang="nl" />
              <link rel="icon" href={favicon} />

              <script type="text/javascript">
                {`(function(l,e,a,d,i,n,f,o){if(!l[i]){l.GlobalLeadinfoNamespace=l.GlobalLeadinfoNamespace||[]; l.GlobalLeadinfoNamespace.push(i);l[i]=function(){(l[i].q=l[i].q||[]).push(arguments)};l[i].t=l[i].t||n; l[i].q=l[i].q||[];o=e.createElement(a);f=e.getElementsByTagName(a)[0];o.async=1;o.src=d;f.parentNode.insertBefore(o,f);} }(window,document,"script","https://cdn.leadinfo.net/ping.js","leadinfo","LI-5FF5B94A6D559"));`}
              </script>

              <script
                id="Cookiebot"
                src="https://consent.cookiebot.com/uc.js"
                data-cbid="aee863b1-3324-433c-ae3c-2db895de0df3"
                data-blockingmode="auto"
                type="text/javascript"
                async
              />

            </Helmet>
          </>
        )
      }}
    />
  )
}

export default SEO
