import React from 'react'
import styled from 'styled-components'
import { MdChevronRight } from 'react-icons/md'
import { Link } from 'gatsby'

const ArrowIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${(props) => props.theme.color.face.secondary};
  display: flex;
  justify-content: center;
`
const ArrowLink = styled(Link)`
  line-height: 20px;
  font-size: ${(props) => props.theme.font.size.sm};
  color: ${(props) => props.theme.color.face.secondary};
  font-family: ${(props) => props.theme.font.family.secondary};
  font-weight: ${(props) => props.theme.font.weight.l};
  &:hover {
    color: ${(props) => props.theme.color.face.secondary};
  }
`
export default function LinkButton({ to = '/', title = '' }) {
  return (
    <div className="d-flex justify-content-end">
      <ArrowLink className="mr-2" to={to}>
        {title}
      </ArrowLink>
      <ArrowIcon>
        <MdChevronRight size={20} color="white" />
      </ArrowIcon>
    </div>
  )
}
