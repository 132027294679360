/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import styled from 'styled-components'

const TitleDefault = styled.h1`
  font-size: ${(props) => props.theme.font.size.xxl};
  font-weight: ${(props) => props.theme.font.weight.l};
  font-family: ${(props) => props.theme.font.family.main};
  @media screen and (max-width: 1024px) {
    font-size: ${(props) => props.theme.font.size.xl};
    font-weight: ${(props) => props.theme.font.weight.m};
  }
`

export default TitleDefault
