/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import ButtonShell from './ButtonShell'

const StyledButton = styled.span`
  background: ${(props) => props.theme.color.face.secondary};
  color: ${(props) => props.theme.color.text.main};
  font-size: ${(props) => props.theme.font.size.m};
  font-family: ${(props) => props.theme.font.family.main};
  display: inline-block;
  min-height: 50px;
  border-radius: 25px;
  line-height: 50px;
  text-align: center;
  width: fit-content;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    background: #a1f9ef;
  }
  & > a,
  & > button {
    color: ${(props) => props.theme.color.text.main};
    padding: 0 60px;
    width: 100%;
    height: 100%;
    display: block;
    font-weight: ${props => props.theme.font.weight.xl};

    @media screen and (max-width: 1024px) {
      font-size: ${(props) => props.theme.font.size.s};
      padding: 0 40px;
    }
    @media screen and (max-width: 456px) {
      font-size: ${(props) => props.theme.font.size.s};
      padding: 0 30px;
    }
  }
`

const ButtonDefault = ({ isAnchor, isCustom = false, to, children, className }) => (
  <StyledButton className={className}>
    {isCustom ? (
      <>
        {children}
      </>
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonDefault
