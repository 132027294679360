/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'

// Theme
import Theme from 'styles/Theme'

// Components
import Footer from './Footer'
import Header from './Header'

// CSS
import 'styles/all.css'

const Content = styled.div`
  padding-top: 96px;
  background: ${(props) => props.theme.color.face.main};
  color: ${(props) => props.theme.color.text.light};

  @media screen and (max-width: 1024px) {
    padding-top: 60px;
  }
`

const Layout = ({ children, activeMenu }) => {
  const [isSticky, setSticky] = useState(false)

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  return (
    <Theme>
      <Header isSticky={isSticky} active={activeMenu} />
      <Content isSticky={isSticky}>{children}</Content>
      <Footer active={activeMenu} />
      <Helmet>
        {/* Start of Tawk.to Script */}
        <script type="text/javascript">
          {`
            var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            (function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/6039169e385de407571a701d/1evfg0n72';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
            })();
          `}
         </script>
        {/* <!--End of Tawk.to Script--> */}
      </Helmet>
    </Theme>
  )
}

export default Layout
