/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import { MdChevronRight } from 'react-icons/md'
import ButtonShell from './ButtonShell'

const StyledButton = styled.span`
  background: ${(props) => props.theme.color.face.light};
  color: ${(props) => props.theme.color.text.contrast};
  font-size: ${(props) => props.theme.font.size.m};
  display: inline-block;
  height: 50px;
  border-radius: 25px;
  line-height: 50px;
  text-align: center;
  width: fit-content;
  cursor: pointer;
  transition: all 300ms ease;
  & > a,
  & > button {
    color: ${(props) => props.theme.color.text.contrast};
    /* padding: 0 60px; */
    width: 100%;
    height: 100%;
    display: block;
    font-weight: ${props => props.theme.font.weight.xl};
  }
  @media screen and (max-width: 1024px) {
    font-size: ${(props) => props.theme.font.size.s};
    padding: 0 40px;
  }
  @media screen and (max-width: 456px) {
    font-size: ${(props) => props.theme.font.size.s};
    padding: 0 30px;
  }
`

const ArrowIcon = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${(props) => props.theme.color.face.secondary};
  display: flex;
  justify-content: center;
`

const ButtonAlt = ({ isAnchor, isCustom, to, children, className }) => (
  <StyledButton className={className}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell className="d-flex align-items-center" to={to} isAnchor={isAnchor}>
        {children}
        <ArrowIcon className="ml-3">
          <MdChevronRight size={20} color="white" />
        </ArrowIcon>
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonAlt
