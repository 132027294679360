/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql, Link } from 'gatsby'

// Components
import CustomLink from 'components/shared/CustomLink'
import ButtonDefault from 'components/elements/ButtonDefault'

const StyledHeader = styled.nav`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 99;

  @media screen and (max-width: 1024px) {
    background-color: ${(props) => props.theme.color.face.main};
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-height: 60px;
  width: 100vw;
`

const Brand = styled(CustomLink)`
  font-size: 0;

  & > svg {
    @media (min-width: 992px) {
      position: relative;
      bottom: 15px;
      height: 70px;
      width: 150px;
    }

    @media (max-width: 991px) {
      height: 50px;
      width: 120px;
    }
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
  font-size: 0;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.text.light};
  height: 2px;
  width: 70%;
  margin: 4px auto;
`

const Header = ({ active }) => {
  const { fields } = useStaticQuery(graphql`
    {
      fields: wordpressWpComponenten(wordpress_id: { eq: 97 }) {
        acf {
          menu {
            link {
              title
              url
              target
            }
            submenu {
              link {
                title
                url
                target
              }
            }
          }
        }
      }
    }
  `)

  return (
    <StyledHeader>
      <Container>
        <TopBar>
          <BrandContainer>
            <Brand className="my-auto" to="/">
              Home
              <svg
                version="1.1"
                id="Laag_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 337 145"
              >
                <g>
                  <g>
                    <path
                      className="st0"
                      fill="#FFFFFF"
                      d="M96.6,102.9H80c-22.3,0-32-9-32-30.3V36.2c16.9,0,18.7,0,18.7,10.8v25.2c0,11.4,2.3,15.1,13.1,15.1h13.2V36.2
			c15.8,0,18.7,0,18.7,11.5v42.9C111.8,101.8,110.3,102.9,96.6,102.9z"
                    />
                    <path
                      className="st0"
                      fill="#FFFFFF"
                      d="M154.8,103h-15.6c-10.3,0-14.4,0.4-14.4-11.7V48.2c0-12.4,4.3-11.8,14.4-11.8h15.1c19.5,0,28.5,7.5,28.5,19
			c0,4.8-2,10.9-8.6,12.5c8.5,1.8,10.6,8.6,10.6,14.3C184.8,93.6,176.7,103,154.8,103z M154.8,50.9h-11.4v11.3h11.9
			c1.2,0,7.4,0.3,8.6-0.3c1.1-0.7,0.5-2.5,0.5-5.1C164.5,54.1,164.5,50.9,154.8,50.9z M155.5,75.8h-12v12.8h11.7
			c1.5,0,8.5,0,9.9-0.8c1.3-0.8,1-3.4,1-6.4C166,76.9,163,75.8,155.5,75.8z"
                    />
                    <path
                      className="st0"
                      fill="#FFFFFF"
                      d="M245.9,102.8c-7.4-0.4-9.2-3-12.6-8.2l-9.9-14.6c0,0-7.8,12.5-12,17.8c-3.3,4.4-5.9,5-11.4,5.2h-13.4l26-34.9
			l-24.3-31.9c4.8,0,16.4-0.4,20.7,1.1c3.5,1.2,5.4,3.9,7.3,6.7l7.2,11.4l9-13.7c1.4-2.1,3-3.7,5.6-4.4c2.9-0.9,7.5-1.1,20.5-1.1
			L234.5,68l26,34.9C250.3,102.9,247.9,102.9,245.9,102.8z"
                    />
                  </g>
                  <path className="st1" fill="#4BFBE7" d="M269.3,83v20h20C289.3,91.9,280.4,83,269.3,83z" />
                </g>
              </svg>
            </Brand>
          </BrandContainer>
        </TopBar>

        <MenuMobile fields={fields} active={active} />
        <MenuDesktop fields={fields} active={active} />
      </Container>
    </StyledHeader>
  )
}

const TopBar = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.color.face.main};
  height: 96px;
  width: 100%;
  top: 0;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    width: fit-content;
    height: 60px;
  }
`

const BrandContainer = styled.div`
  margin-top: 33px;
  margin-bottom: auto;

  @media screen and (min-width: 576px) {
    margin-left: calc(50vw - 270px);
  }

  @media screen and (min-width: 768px) {
    margin-left: calc(50vw - 370px);
  }

  @media screen and (min-width: 992px) {
    margin-left: calc(50vw - 496px);
  }

  @media screen and (min-width: 1200px) {
    margin-left: calc(50vw - 600px);
  }

  @media screen and (min-width: 1400px) {
    margin-left: calc(50vw - 640px);
  }

  @media screen and (max-width: 576px) {
    margin-left: 15px;
    margin-right: 15px;
  }

  @media screen and (max-width: 1024px) {
    margin-top: auto;
  }
`

const MenuWrapper = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  padding: 0;
  max-height: ${(props) => (props.isOpen ? '100vh' : 0)};
  overflow: hidden;
  transition: all 300ms ease;

  & > ul {
    padding: 50px 0 0;
    list-style-type: none;
    text-align: center;
    width: 60%;
    margin: 0 auto;

    & li {
      padding: 0;

      & a {
        font-size: ${(props) => props.theme.font.size.l};
        border-bottom: 1px solid #f1f1f1;
        display: block;
        padding: 10px 0;
      }

      &:last-child a {
        border-bottom: none;
      }
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
  z-index: 3;
  font-size: 0;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.text.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const MenuWrapperItems = styled.div`
  position: relative;
  bottom: 50px;
`

const MenuMobile = ({ fields, active }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="ml-auto d-block d-lg-none">
      <NavButton
        role="button"
        className="d-flex flex-column justify-content-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
        Menu
      </NavButton>
      <MenuWrapper isOpen={isOpen}>
        <NavButtonClose role="button" onClick={() => setIsOpen(!isOpen)}>
          <NavButtonCloseCross />
          Sluiten
        </NavButtonClose>
        <MenuWrapperItems className="py-5 d-flex align-center-center justify-content-center flex-column h-100">
          {fields.acf.menu.map(({ link: { url, title }, submenu }) => (
            <div className="mb-4 my-lg-auto" key={title}>
              {submenu && (
                <MobileMenuItemNonButton>
                  {title}
                </MobileMenuItemNonButton>
              )}
              {!submenu && (
                <MobileMenuItem to={url} active={title === active}>
                  {title}
                </MobileMenuItem>
              )}
              {submenu &&
                submenu.map(({ link: { title: subTitle, url: subUrl } }) => (
                  <MobileMenuSubItem
                    className="my-2"
                    key={subTitle}
                    to={subUrl}
                  >
                    {subTitle}
                  </MobileMenuSubItem>
                  ))}
            </div>
          ))}
        </MenuWrapperItems>
      </MenuWrapper>
    </div>
  )
}

const MobileMenuItemCSS = css`
display: block;
  color: ${(props) => props.theme.color.text.light};
  font-size: ${(props) => props.theme.font.size.l};
  text-align: center;
  &:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    margin-left: 4px;
    margin-top: 18px;
    background: ${(props) => props.theme.color.face.secondary};
    border-top-right-radius: 8px;
  }
`

const MobileMenuItem = styled(CustomLink)`
  ${MobileMenuItemCSS};
`

const MobileMenuItemNonButton = styled.span`
  ${MobileMenuItemCSS};
`

const MobileMenuSubItem = styled(MobileMenuItem)`
  font-size: ${(props) => props.theme.font.size.m};
  &:after {
    width: 0;
    height: 0;
  }
`
const ContainerRight = styled.div`
  margin-right: calc(50vw - 570px);

  @media screen and (min-width: 576px) {
    margin-right: calc(50vw - 270px);
  }

  @media screen and (min-width: 768px) {
    margin-right: calc(50vw - 384px);
  }

  @media screen and (min-width: 992px) {
    margin-right: calc(50vw - 496px);
  }

  @media screen and (min-width: 1200px) {
    margin-right: calc(50vw - 600px);
  }

  @media screen and (min-width: 1400px) {
    margin-right: calc(50vw - 640px);
  }

  @media screen and (max-width: 576px) {
    margin-right: 15px;
  }
`

const MenuDesktop = ({ fields, active }) => {
  const [currentSubMenu, setCurrentSubMenu] = useState({
    visible: false,
    data: null,
  })

  return (
    <StyledMenuDesktop className="ml-auto d-none d-lg-block">
      <MenuDesktopContainer className="position-relative">
        <MainMenu className="d-flex justify-content-end">
          {fields.acf.menu.map(({ link: { url, title }, submenu }) => (
            <Item
              active={
                title === active ||
                (currentSubMenu.visible && currentSubMenu.title === title)
              }
              key={title}
              onMouseOver={() => {
                setCurrentSubMenu({
                  data: submenu,
                  title,
                  visible: submenu ? 1 : 0,
                })
              }}
            >
              {submenu ? (
                <span>{title}</span>
              ) : (
                <CustomLink to={url}>{title}</CustomLink>
              )}
            </Item>
          ))}
        </MainMenu>
        <SubMenu
          visible={currentSubMenu.visible}
          onMouseOut={() => {
            setCurrentSubMenu({ ...currentSubMenu, ...{ visible: false } })
          }}
          onMouseOver={() => {
            setCurrentSubMenu({ ...currentSubMenu, ...{ visible: true } })
          }}
        >
          {currentSubMenu.data &&
            currentSubMenu.data.map(
              ({ link: { title: subTitle, url: subUrl } }) => (
                <SubItem key={subTitle} to={subUrl}>
                  <span>{subTitle}</span>
                </SubItem>
              )
            )}
          <PhoneButton visible={currentSubMenu.visible} submenuVisible>
            <ButtonDefault to="tel:0307009763" isAnchor>
              <div>030 700 97 63</div>
            </ButtonDefault>
          </PhoneButton>
        </SubMenu>
        {!currentSubMenu.visible && (
          <PhoneButton visible isAbsolute>
            <ButtonDefault to="tel:0307009763" className="ml-auto" isAnchor>
              <div>030 700 97 63</div>
            </ButtonDefault>
          </PhoneButton>
        )}
      </MenuDesktopContainer>
    </StyledMenuDesktop>
  )
}

const MenuDesktopContainer = styled(ContainerRight)`
  margin-left: 240px;

  @media screen and (max-width: 1200px) {
    margin-left: 120px;
  }
`

const PhoneButton = styled.div`
  position: ${(props) => (props.isAbsolute ? 'absolute' : 'relative')};
  margin-top: ${(props) => (props.isAbsolute ? '-25px' : '20px')};
  margin-right: 0;
  height: ${(props) => (props.isAbsolute ? '50px' : '10px')};
  margin-left: auto;
  right: 0;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  transition: all 360ms ease;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const StyledMenuDesktop = styled.div`
  border-bottom-left-radius: 96px;
  position: relative;
  background: ${(props) => props.theme.color.face.light};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`

const MainMenu = styled.div`
  height: 96px;
  max-width: 670px;
`

const Item = styled.div`
  color: ${(props) => props.theme.color.text.main};
  font-size: ${(props) => props.theme.font.size.sm};
  line-height: ${(props) => props.theme.font.size.sm};
  font-family: ${(props) => props.theme.font.family.main};
  text-transform: uppercase;
  margin: auto 0 auto 50px;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    width: ${(props) => (props.active ? '10px' : '0px')};
    height: ${(props) => (props.active ? '10px' : '0px')};
    margin-left: -16px;
    margin-top: 2px;
    background: ${(props) => props.theme.color.face.secondary};
    border-top-left-radius: 10px;
  }

  & > a {
    color: ${(props) => props.theme.color.text.main};
    font-size: ${(props) => props.theme.font.size.sm};

    &[aria-current] {
    }

    &:hover {
    }
  }
`

const SubMenu = styled.div`
  border-top: ${(props) =>
    props.visible ? `1px solid ${props.theme.color.face.main}` : ''};
  display: flex;
  padding: ${(props) => (props.visible ? '0 0 30px 0' : '0')};
  flex-wrap: wrap;
  max-width: 670px;
  transition: all 200ms ease;
  max-height: ${(props) => (props.visible ? '100vh' : 0)};
  overflow: hidden;
`

const SubItem = styled(Link)`
  width: 30.333%;
  margin: 24px 0;
  margin-right: 3%;

  span {
    white-space: pre-line;
    width: min-content;
    display: block;
  }
`

export default Header
