/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { ContainerLeft, ContainerRight, CustomLink } from 'components/shared'
import LinkedinIcon from 'img/icon-linkedin.svg'

import Logo from 'img/logo.inline.svg'

const menuData = [
  {
    title: 'Upgrades',
    children: [
      {
        title: 'Marketing Automation',
        url: '/upgrades/marketing-automation/',
      },
      {
        title: 'Customer Data',
        url: '/upgrades/customer-data/',
      },
      {
        title: 'Marketing Management',
        url: '/upgrades/marketing-management/',
      },
      {
        title: 'Technology Integration',
        url: '/upgrades/technology-integration/',
      },
      {
        title: 'UX Design',
        url: '/upgrades/ux-design/',
      },
      {
        title: 'Customer Satisfaction',
        url: '/upgrades/customer-satisfaction/',
      },
      {
        title: 'Data Management',
        url: '/upgrades/data-management/',
      },
    ],
  },
  {
    title: 'Menu',
    children: [
      {
        url: '/werkwijze/',
        title: 'Werkwijze',
      },
      {
        url: '/upgrades/',
        title: 'Upgrades',
      },
      {
        url: '/cases/',
        title: 'Cases',
      },
      {
        url: '/blogs/',
        title: 'Blogs',
      },
      {
        url: '/contact/',
        title: 'Contact',
      },
    ],
  },
]
const Footer = () => (
  <StyledFooter className="d-flex">
    <ContactSection className="d-flex flex-column">
      <ContactContent className="d-flex justify-content-between my-auto pb-3 pb-lg-0 pl-5">
        <span className="ml-3 my-1 my-sm-2 mr-auto mr-sm-0">Meer weten?</span>
        <Link to="/contact">
          <button
            className="ml-auto ml-sm-0 mr-auto mr-sm-0 my-0 my-sm-2"
            type="button"
          >
            CONTACT
          </button>
        </Link>
      </ContactContent>
    </ContactSection>
    <ContainerLeft className="mb-auto d-xl-block d-none">
      <Brand to="/">
        <Logo />
      </Brand>
    </ContainerLeft>
    <MainFooter className="ml-3 ml-xl-0">
      <ContainerRight className="d-flex flex-wrap">
        <div className="col-sm-4 my-3">
          <HeadItem>Contact</HeadItem>
          <div>
            Amersfoortseweg 28
            <br />
            3752 LK
            <br />
            Bunschoten
            <br />
            <br />
            <a href="tel:0307009763">030 700 97 63</a>
            <br />
            <a className="mail" href="mailto:upgrade@ubx.nl">
              upgrade@ubx.nl
            </a>
          </div>
          <div className="d-flex mt-3">
            <a
              className="mr-2"
              href="https://www.linkedin.com/company/ubx-upgrading-customer-journeys/"
              target="_blank"
              rel="noreferrer"
            >
              <SocialIcon src={LinkedinIcon} alt="linkedin" />
            </a>
          </div>
        </div>
        {menuData.map(({ title, children }) => (
          <div key={title} className="col-sm-4 my-3 d-block">
            <HeadItem>{title}</HeadItem>
            {children.map(({ title: childTitle, url }) => (
              <MenuItem key={childTitle} to={url}>
                {childTitle}
              </MenuItem>
            ))}
          </div>
        ))}
      </ContainerRight>
    </MainFooter>
  </StyledFooter>
)

export default Footer

const ContactSection = styled.div`
  background-color: ${(props) => props.theme.color.face.secondary};
  font-family: ${(props) => props.theme.font.family.main};
  font-size: ${(props) => props.theme.font.size.l};
  color: ${(props) => props.theme.color.text.light};
  position: absolute;
  right: 0;
  height: 100px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  margin-top: -50px;
  line-height: 44px;

  button {
    border: 2px solid ${(props) => props.theme.color.face.main};
    border-radius: 25px;
    padding: 0 60px;
    color: ${(props) => props.theme.color.text.main};
    font-size: ${(props) => props.theme.font.size.m};
    height: 50px;
    transition: all 300ms ease;

    &:hover {
      background: ${(props) => props.theme.color.text.main};
      color: ${(props) => props.theme.color.face.secondary};
    }
  }

  @media screen and (max-width: 1024px) {
    height: 140px;
    border-top-left-radius: 70px;
    border-bottom-left-radius: 70px;
    margin-top: -70px;
  }

  @media screen and (max-width: 768px) {
    font-size: ${(props) => props.theme.font.size.xm};
  }
`

const ContactContent = styled(ContainerRight)`
  width: 600px;

  @media screen and (max-width: 1024px) {
    width: 300px;
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 575px) {
    width: 250px;
    margin-left: 0;
  }
`

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
  color: ${(props) => props.theme.color.text.main};
`

const MainFooter = styled.div`
  background: white;
  padding-top: 70px;
  padding-bottom: 40px;
  padding-left: 180px;
  width: 100%;
  border-top-left-radius: 400px;
  font-size: ${(props) => props.theme.font.size.sm};
  line-height: ${(props) => props.theme.font.size.l};
  font-family: ${(props) => props.theme.font.family.secondary};

  a.mail {
    color: ${(props) => props.theme.color.face.secondary};
  }

  @media screen and (max-width: 1024px) {
    padding-left: 15px;
    border-top-left-radius: 40px;
  }
`

const HeadItem = styled.div`
  color: ${(props) => props.theme.color.text.main};
  font-size: ${(props) => props.theme.font.size.m};
  line-height: ${(props) => props.theme.font.size.m};
  font-family: ${(props) => props.theme.font.family.main};
  text-transform: uppercase;
  margin-left: 20px;
  margin-bottom: 10px;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    margin-left: -16px;
    margin-top: 2px;
    background: ${(props) => props.theme.color.face.secondary};
    border-top-left-radius: 10px;
  }
`

const MenuItem = styled(Link)`
  font-size: ${(props) => props.theme.font.size.sm};
  display: block;
  margin: 4px 0;
`

const Brand = styled(CustomLink)`
  & > svg {
    height: 56px;
    width: 200px;
  }
`

const SocialIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;

  &:hover {
    opacity: 0.7;
  }
`
