import styled from 'styled-components'

export default styled.div`
  margin-left: calc(50vw - 570px);

  @media screen and (min-width: 576px) {
    margin-left: calc(50vw - 270px);
  }
  @media screen and (min-width: 768px) {
    margin-left: calc(50vw - 360px);
  }
  @media screen and (min-width: 992px) {
    margin-left: calc(50vw - 480px);
  }
  @media screen and (min-width: 1200px) {
    margin-left: calc(50vw - 570px);
  }
  @media screen and (max-width: 576px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`
